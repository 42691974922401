import axios from "axios";
import * as React from "react";
import Layout from "../components/templates/Layout";
import Moment from "react-moment";

const formatActivyURL = (URL) => {
   const ArrayOfURL = URL.split("/");

   return {
      name: `${ArrayOfURL[3]}/${ArrayOfURL[4]}`,
      url: `https://github.com/${ArrayOfURL[3]}/${ArrayOfURL[4]}`,
   };
};

// markup
const IndexPage = () => {
   const [commits, setCommits] = React.useState(false);
   const [issues, setIssues] = React.useState(false);
   const [pullRequests, setPullRequests] = React.useState(false);

   const githubProfil = "https://github.com/MKlblangenois";

   React.useEffect(() => {
      axios({
         method: "get",
         url: "https://api.github.com/search/commits?q=author:mklblangenois&sort=author-date&order=desc&page=1",
         headers: {
            Accept: "application/vnd.github.cloak-preview",
            Authorization: "token c63d56998c80b635ab19deb58139cea216ddd4ed",
         },
      })
         .then((res) => {
            console.log(res);
            setCommits(res.data);
         })
         .catch((error) => {
            console.log("Error", error.message);
         });

      axios({
         method: "get",
         url: "https://api.github.com/search/issues?q=author:mklblangenois+is:issue&sort=author-date&order=desc&page=1",
         headers: {
            Accept: "application/vnd.github.cloak-preview",
            Authorization: "token c63d56998c80b635ab19deb58139cea216ddd4ed",
         },
      })
         .then((res) => {
            setIssues(res.data);
         })
         .catch((error) => {
            console.log("Error", error.message);
         });

      axios({
         method: "get",
         url: "https://api.github.com/search/issues?q=author:mklblangenois+is:pull-request&sort=author-date&order=desc&page=1",
         headers: {
            Accept: "application/vnd.github.cloak-preview",
            Authorization: "token c63d56998c80b635ab19deb58139cea216ddd4ed",
         },
      })
         .then((res) => {
            setPullRequests(res.data);
         })
         .catch((error) => {
            console.log("Error", error.message);
         });
   }, []);

   return (
      <Layout title="Dashboard">
         <div className="">
            <div className="">
               <h2 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Overview</h2>
               <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3" x-max={1}>
                  {/* Card */}

                  {commits ? (
                     <div className="bg-white dark:bg-gray-900 overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                           <div className="flex items-center">
                              <div className="flex-shrink-0">
                                 <svg className="h-6 w-6 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                    <path fillRule="evenodd" fill="currentColor" d="M1.643 3.143L.427 1.927A.25.25 0 000 2.104V5.75c0 .138.112.25.25.25h3.646a.25.25 0 00.177-.427L2.715 4.215a6.5 6.5 0 11-1.18 4.458.75.75 0 10-1.493.154 8.001 8.001 0 101.6-5.684zM7.75 4a.75.75 0 01.75.75v2.992l2.028.812a.75.75 0 01-.557 1.392l-2.5-1A.75.75 0 017 8.25v-3.5A.75.75 0 017.75 4z"></path>
                                 </svg>
                              </div>
                              <div className="ml-5 w-0 flex-1">
                                 <dl>
                                    <dt className="text-sm font-medium text-gray-500 truncate">Public commits</dt>
                                    <dd>
                                       <div className="text-lg font-medium text-gray-900 dark:text-white">{commits.total_count}</div>
                                    </dd>
                                 </dl>
                              </div>
                           </div>
                        </div>
                        <div className="bg-gray-50 dark:bg-gray-900 px-5 py-3">
                           <div className="text-sm">
                              <a href={githubProfil + "#js-contribution-activity"} target="_blank" className="font-medium hover:underline focus:underline dark:text-white">
                                 View all
                              </a>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div className="w-full h-36 bg-gray-50 dark:bg-gray-900 shadow rounded-lg animate-pulse"></div>
                  )}

                  {pullRequests ? (
                     <div className="bg-white dark:bg-gray-900 overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                           <div className="flex items-center">
                              <div className="flex-shrink-0">
                                 <svg className="h-6 w-6 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                    <path fillRule="evenodd" fill="currentColor" d="M7.177 3.073L9.573.677A.25.25 0 0110 .854v4.792a.25.25 0 01-.427.177L7.177 3.427a.25.25 0 010-.354zM3.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 113 2.122v5.256a2.251 2.251 0 11-1.5 0V5.372A2.25 2.25 0 011.5 3.25zM11 2.5h-1V4h1a1 1 0 011 1v5.628a2.251 2.251 0 101.5 0V5A2.5 2.5 0 0011 2.5zm1 10.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.75 12a.75.75 0 100 1.5.75.75 0 000-1.5z"></path>
                                 </svg>
                              </div>
                              <div className="ml-5 w-0 flex-1">
                                 <dl>
                                    <dt className="text-sm font-medium text-gray-500 truncate">Public pull requests</dt>
                                    <dd>
                                       <div className="text-lg font-medium text-gray-900 dark:text-white">{pullRequests.total_count}</div>
                                    </dd>
                                 </dl>
                              </div>
                           </div>
                        </div>
                        <div className="bg-gray-50 dark:bg-gray-900 px-5 py-3">
                           <div className="text-sm">
                              <a href={githubProfil + "#js-contribution-activity"} target="_blank" className="font-medium hover:underline focus:underline dark:text-white">
                                 View all
                              </a>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div className="w-full h-36 bg-gray-50 dark:bg-gray-900 shadow rounded-lg animate-pulse"></div>
                  )}

                  {issues ? (
                     <div className="bg-white dark:bg-gray-900 overflow-hidden shadow rounded-lg">
                        <div className="p-5">
                           <div className="flex items-center">
                              <div className="flex-shrink-0">
                                 <svg className="h-6 w-6 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                    <path fillRule="evenodd" fill="currentColor" d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm9 3a1 1 0 11-2 0 1 1 0 012 0zm-.25-6.25a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0v-3.5z"></path>
                                 </svg>
                              </div>
                              <div className="ml-5 w-0 flex-1">
                                 <dl>
                                    <dt className="text-sm font-medium text-gray-500 truncate">Issues opened</dt>
                                    <dd>
                                       <div className="text-lg font-medium text-gray-900 dark:text-white">{issues.total_count}</div>
                                    </dd>
                                 </dl>
                              </div>
                           </div>
                        </div>
                        <div className="bg-gray-50 dark:bg-gray-900 px-5 py-3">
                           <div className="text-sm">
                              <a href={githubProfil + "#js-contribution-activity"} target="_blank" className="font-medium hover:underline focus:underline dark:text-white">
                                 View all
                              </a>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div className="w-full h-36 bg-gray-50 dark:bg-gray-900 shadow rounded-lg animate-pulse"></div>
                  )}
               </div>
            </div>

            <h2 className="mt-8 text-lg leading-6 font-medium text-gray-900 dark:text-white">Recent activity</h2>
            {/* Activity list (smallest breakpoint only) */}
            <div className="shadow sm:hidden">
               <ul className="mt-2 divide-y divide-gray-200 dark:divide-gray-700 overflow-hidden shadow sm:hidden" x-max={1}>
                  {commits && issues && pullRequests ? (
                     commits.items
                        .concat(issues.items)
                        .concat(pullRequests.items)
                        .sort((a, b) => {
                           let aDate = new Date(a.commit?.author.date || a.created_at);
                           let bDate = new Date(b.commit?.author.date || b.created_at);

                           return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
                        })
                        .map((commit) => {
                           return (
                              <li>
                                 <a href={commit.repository?.html_url || commit.html_url} target="_blank" className="block px-4 py-4 bg-white dark:bg-gray-900 hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <span className="flex items-center space-x-4">
                                       <span className="flex-1 flex space-x-2 truncate">
                                          {commit.commit ? (
                                             <svg className="h-5 w-5 flex-shrink-0 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                                <path fillRule="evenodd" fill="currentColor" d="M1.643 3.143L.427 1.927A.25.25 0 000 2.104V5.75c0 .138.112.25.25.25h3.646a.25.25 0 00.177-.427L2.715 4.215a6.5 6.5 0 11-1.18 4.458.75.75 0 10-1.493.154 8.001 8.001 0 101.6-5.684zM7.75 4a.75.75 0 01.75.75v2.992l2.028.812a.75.75 0 01-.557 1.392l-2.5-1A.75.75 0 017 8.25v-3.5A.75.75 0 017.75 4z"></path>
                                             </svg>
                                          ) : commit.pull_request ? (
                                             <svg className="h-5 w-5 flex-shrink-0 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                                <path fillRule="evenodd" fill="currentColor" d="M7.177 3.073L9.573.677A.25.25 0 0110 .854v4.792a.25.25 0 01-.427.177L7.177 3.427a.25.25 0 010-.354zM3.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 113 2.122v5.256a2.251 2.251 0 11-1.5 0V5.372A2.25 2.25 0 011.5 3.25zM11 2.5h-1V4h1a1 1 0 011 1v5.628a2.251 2.251 0 101.5 0V5A2.5 2.5 0 0011 2.5zm1 10.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.75 12a.75.75 0 100 1.5.75.75 0 000-1.5z"></path>
                                             </svg>
                                          ) : (
                                             <svg className="h-5 w-5 flex-shrink-0 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                                <path fillRule="evenodd" fill="currentColor" d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm9 3a1 1 0 11-2 0 1 1 0 012 0zm-.25-6.25a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0v-3.5z"></path>
                                             </svg>
                                          )}

                                          <span className="flex flex-col text-gray-500 dark:text-white text-sm truncate">
                                             <span className="truncate">{commit.commit?.message || commit.title}</span>
                                             <span className="flex items-center space-x-1 text-gray-500 truncate overflow-hidden dark:text-gray-400">
                                                <span className="inline">push to </span>
                                                <object className="inline truncate">
                                                   <a target="_blank" href={commit.repository?.html_url || formatActivyURL(commit.html_url).url} className="font-bold hover:underline truncate focus:underline">
                                                      {commit.repository?.full_name || formatActivyURL(commit.html_url).name}
                                                   </a>
                                                </object>
                                             </span>
                                             <span className="block mt-2">
                                                <Moment format="DD MMMM YYYY" date={commit.commit?.author.date || commit.created_at} />
                                             </span>
                                          </span>
                                       </span>
                                       <svg className="flex-shrink-0 h-5 w-5 text-gray-400" x-description="Heroicon name: solid/chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                       </svg>
                                    </span>
                                 </a>
                              </li>
                           );
                        })
                  ) : (
                     <>
                        <li className="h-20 bg-gray-100 dark:bg-gray-900 animate-pulse"></li>
                        <li className="h-20 bg-gray-100 dark:bg-gray-900 animate-pulse"></li>
                        <li className="h-20 bg-gray-100 dark:bg-gray-900 animate-pulse"></li>
                        <li className="h-20 bg-gray-100 dark:bg-gray-900 animate-pulse"></li>
                        <li className="h-20 bg-gray-100 dark:bg-gray-900 animate-pulse"></li>
                     </>
                  )}
               </ul>
            </div>
            {/* Activity table (small breakpoint and up) */}
            <div className="hidden sm:block">
               <div className="flex flex-col mt-2">
                  <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                     <table className="min-w-full divide-y divide-gray-200 table-fixed">
                        <thead>
                           <tr>
                              <th className="px-6 py-3 bg-gray-50 dark:bg-gray-900 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
                              <th className="hidden xl:block px-6 py-3 bg-gray-50 dark:bg-gray-900 text-left text-xs font-medium text-gray-500 uppercase">Repository</th>
                              <th className="px-6 py-3 bg-gray-50 dark:bg-gray-900 text-right text-xs font-medium text-gray-500 uppercase">Date</th>
                           </tr>
                        </thead>
                        <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700" x-max={1}>
                           {commits &&
                              issues &&
                              pullRequests &&
                              commits.items
                                 .concat(issues.items)
                                 .concat(pullRequests.items)
                                 .sort((a, b) => {
                                    let aDate = new Date(a.commit?.author.date || a.created_at);
                                    let bDate = new Date(b.commit?.author.date || b.created_at);

                                    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
                                 })
                                 .map((commit) => {
                                    return (
                                       <tr className="bg-white dark:bg-gray-900">
                                          <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                             <div className="flex">
                                                <a href={commit.repository?.html_url || commit.html_url} target="_blank" className="group inline-flex space-x-2 truncate text-sm text-gray-500 hover:text-gray-900 dark:hover:text-gray-600" title={commit.commit?.message || commit.title}>
                                                   {commit.commit ? (
                                                      <svg className="h-5 w-5 flex-shrink-0 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                                         <path fillRule="evenodd" fill="currentColor" d="M1.643 3.143L.427 1.927A.25.25 0 000 2.104V5.75c0 .138.112.25.25.25h3.646a.25.25 0 00.177-.427L2.715 4.215a6.5 6.5 0 11-1.18 4.458.75.75 0 10-1.493.154 8.001 8.001 0 101.6-5.684zM7.75 4a.75.75 0 01.75.75v2.992l2.028.812a.75.75 0 01-.557 1.392l-2.5-1A.75.75 0 017 8.25v-3.5A.75.75 0 017.75 4z"></path>
                                                      </svg>
                                                   ) : commit.pull_request ? (
                                                      <svg className="h-5 w-5 flex-shrink-0 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                                         <path fillRule="evenodd" fill="currentColor" d="M7.177 3.073L9.573.677A.25.25 0 0110 .854v4.792a.25.25 0 01-.427.177L7.177 3.427a.25.25 0 010-.354zM3.75 2.5a.75.75 0 100 1.5.75.75 0 000-1.5zm-2.25.75a2.25 2.25 0 113 2.122v5.256a2.251 2.251 0 11-1.5 0V5.372A2.25 2.25 0 011.5 3.25zM11 2.5h-1V4h1a1 1 0 011 1v5.628a2.251 2.251 0 101.5 0V5A2.5 2.5 0 0011 2.5zm1 10.25a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM3.75 12a.75.75 0 100 1.5.75.75 0 000-1.5z"></path>
                                                      </svg>
                                                   ) : (
                                                      <svg className="h-5 w-5 flex-shrink-0 text-gray-500" height="16" viewBox="0 0 16 16" version="1.1" width="16" aria-hidden="true">
                                                         <path fillRule="evenodd" fill="currentColor" d="M8 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM0 8a8 8 0 1116 0A8 8 0 010 8zm9 3a1 1 0 11-2 0 1 1 0 012 0zm-.25-6.25a.75.75 0 00-1.5 0v3.5a.75.75 0 001.5 0v-3.5z"></path>
                                                      </svg>
                                                   )}
                                                   <p className="text-gray-800 dark:text-white truncate group-hover:text-gray-900 dark:group-hover:text-gray-400">{commit.commit?.message || commit.title}</p>
                                                </a>
                                             </div>
                                          </td>
                                          <td className="hidden xl:block px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-white">
                                             <a href={commit.repository?.html_url || formatActivyURL(commit.html_url).url} target="_blank" className="dark:text-gray-400 truncate">
                                                {commit.repository?.full_name || formatActivyURL(commit.html_url).name}
                                             </a>
                                          </td>
                                          <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                             <Moment format="DD MMM YYYY" date={commit.commit?.author?.date || commit.created_at} />
                                          </td>
                                       </tr>
                                    );
                                 })}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </Layout>
   );
};

export default IndexPage;
